.offcanvas {
  &-body {
    &-timesheet {
      padding: 0;
    }
  }

  &-header {
    &-timesheet {
      background: $success;
      color: $white;
      padding: 0.75rem 0.5rem;
    }
  }
}
