.btn {
  &-group {
    &-save-clear {
      display: block;
      margin-bottom: map-get($spacers, 2);
      padding: 0 map-get($spacers, 2);

      @include media-breakpoint-up(sm) {
        display: flex;
      }
    }
  }
}
