.badge {
  &-gcls-fmla {
    background-color: $gcls-pool;
    color: $white;
    cursor: zoom-in;
    margin-right: map-get($spacers, 1);
  }

  &-gcls-deleted-by,
  &-gcls-updated-by {
    color: $white;
    cursor: zoom-in;
  }

  &-gcls-deleted-by {
    background-color: $danger;
  }

  &-gcls-updated-by {
    background-color: $warning;
  }
}
