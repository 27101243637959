.fieldset {
  &-subheader {
    background-color: $gray-200;
    font-weight: $font-weight-bold;
    line-height: 1;
    padding: 0.75rem 0.5rem;
  }

  &-note {
    padding: map-get($spacers, 2);
  }

  &-other,
  &-worked {
    padding: 0 map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 2);
  }
}

.form {
  &-control {
    &-time {
      font-family: $font-family-monospace;
      font-size: $font-size-sm;
      padding: $input-padding-y;
      text-align: right;
    }
  }

  &-label {
    &-time {
      font-size: $font-size-sm;
      text-align: right;
      width: 100%;
    }
  }
}

.input-group-text {
  &-time {
    background-color: $gray-200;
    padding: $input-group-addon-padding-y;
  }
}
