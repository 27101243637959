.btn-close {
  &.btn-close-timesheet {
    color: $white;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    background: transparent;
    line-height: 1;
    margin-right: -0.5rem;
    opacity: 0.75;

    &:not(:disabled):not(.disabled) {
      &:focus,
      &:hover {
        opacity: 1;
      }
    }
  }
}
