.exempt-status {
  &-checkbox {
    border: $border-width solid $dark;
    font-size: 2rem;
    line-height: 1;
    padding: map-get($spacers, 2);
  }
}

.total {
  &-hours {
    &-number {
      font-family: $font-family-monospace;
      line-height: 1;
      margin-top: map-get($spacers, 1);
      text-align: right;
      white-space: nowrap;
    }
  }
}

.wrapper {
  &-deleted-updated-by {
    margin-bottom: map-get($spacers, 1);
    text-align: center;
  }

  &-subtotal,
  &-total {
    line-height: 1;
    padding: map-get($spacers, 2) map-get($spacers, 2);
  }

  &-subtotal {
    border-top: 1px solid $input-border-color;
  }

  &-total {
    border-top: 5px double $input-border-color;
  }
}

@media print {
  table.table tfoot {
    display: table-row-group;
  }
}
