.card {
  &-body {
    &.card-body-cal {
      min-height: 4rem;
      padding: map-get($spacers, 1);

      @include media-breakpoint-up(md) {
        min-height: 6rem;
      }
    }
  }

  &-cal {
    &.card-cal {
      button {
        visibility: hidden;
      }

      &-is-current {
        flex: 2 0 0;
      }

      &-not-submitted {
        cursor: pointer;
      }

      &:focus,
      &:hover {
        button {
          visibility: visible;
        }
      }
    }
  }

  &-footer {
    &-cal {
      background-color: transparent;
      border-top: none;
      padding: map-get($spacers, 1);
    }
  }

  &-text {
    &-cal {
      &-detail {
        padding-bottom: map-get($spacers, 3);
      }

      &-summary {
        padding-bottom: map-get($spacers, 3);
        padding-top: map-get($spacers, 3);
      }
    }
  }
}

.card-group-timesheet {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: map-get($spacers, 4);

    &:last-child {
      margin-bottom: map-get($spacers, 1);
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    flex-flow: row wrap;

    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      &:last-child {
        margin-bottom: 0;
      }

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-end-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }

          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-start-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }

          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}
