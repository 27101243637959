.cal {
  &-day {
    &-history {
      color: currentColor;
      display: none;
      inset: map-get($spacers, 1) 0 0 0;
      position: absolute;

      &:focus,
      &:hover {
        color: currentColor;
      }
    }

    &-name {
      display: block;
      flex-grow: 1;
      height: 2rem;
      line-height: 2rem;
      min-height: 2rem;

      @include media-breakpoint-up(sm) {
        display: none;
      }

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &-name-short {
      display: none;
      flex-grow: 1;
      height: 2rem;
      line-height: 2rem;
      min-height: 2rem;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &-number {
      background: $gray-200;
      border-bottom: 2px solid $card-border-color;
      border-left: 2px solid $card-border-color;
      font-weight: $font-weight-medium;
      height: 2rem;
      position: relative;
      min-height: 2rem;
      min-width: 2rem;
      padding: map-get($spacers, 1);
      text-align: center;
      width: 2rem;

      &-text {
        display: inline;
      }

      &:focus,
      &:hover {
        .cal-day-history {
          display: block;
        }

        .cal-day-number-text {
          display: none;
        }
      }

      &.cal-is-current {
        background: $success;
        border-bottom: none;
        border-left: none;
        font-weight: $font-weight-medium;
      }
    }

    &-subheader {
      border-bottom: 1px solid $card-border-color;
      color: shift-color($text-muted, -37.5%);
      display: inline-block;
      margin-bottom: map-get($spacers, 1);
      margin-left: -1 * map-get($spacers, 1);
      margin-top: map-get($spacers, 1);
      padding-left: map-get($spacers, 1);
      padding-right: map-get($spacers, 2);

      &.cal-day-no-hours {
        display: none;

        @include media-breakpoint-up(md) {
          border-bottom-color: transparent;
          color: transparent;
          display: inline-block;
        }
      }
    }

    &-subtotal {
      border-top: 1px solid $card-border-color;
      font-family: $font-family-monospace;
      font-weight: $font-weight-medium;
      margin-top: map-get($spacers, 1);
      text-align: right;

      &.cal-day-no-hours {
        display: none;

        @include media-breakpoint-up(md) {
          border-top-color: transparent;
          color: transparent;
          display: block;
        }
      }
    }

    &-total {
      align-items: center;
      border-top: 3px double $card-border-color;
      display: flex;
      margin-top: map-get($spacers, 1);
      padding-top: map-get($spacers, 1);

      &.cal-day-no-hours {
        display: none;

        @include media-breakpoint-up(md) {
          border-top-color: transparent;
          color: transparent;
          display: flex;
        }
      }
    }
  }

  &-drag-handle {
    color: $gray-500;
    cursor: move;
    cursor: grab;
    padding: 0 map-get($spacers, 1);

    &.cal-is-current {
      color: $white;
      opacity: 0.75;
    }
  }

  &-header {
    align-items: center;
    display: flex;
    padding-left: map-get($spacers, 1) + (map-get($spacers, 1) / 2);

    &.cal-is-current {
      background-color: $success;
      color: $white;
      font-weight: $font-weight-medium;
    }
  }

  &-week {
    &-subtotal {
      display: flex;
      font-weight: bold;
      justify-content: end;
      padding: map-get($spacers, 1);
      margin-bottom: map-get($spacers, 4);
    }
  }
}
