@media print {
  .container {
    width: auto;
  }

  .table-employees {
    --bs-table-color: $black;

    font-size: 12px;

    .badge {
      display: none;
    }

    .table-link,
    .text-muted,
    .text-muted-more {
      color: $black !important;
    }

    tfoot {
      border-color: $black !important;
    }

    th {
      border-bottom-color: $black;
    }

    td,
    th {
      padding: 2px !important;

      &.col-number,
      &.text-end {
        padding: 2px 8px !important;
      }

      .label {
        display: none;
      }

      &:first-child {
        padding-left: 1px !important;
      }

      &:last-child {
        padding-right: 1px !important;
      }
    }

    tr {
      break-inside: avoid;
      page-break-after: auto;
      page-break-inside: avoid;
    }
  }

  .table-responsive {
    margin-bottom: map-get($spacers, 3);
    max-height: inherit;
    overflow-x: inherit;
  }

  [data-gcls-wrapper="header-subtitle"] {
    &.mb-4 {
      margin-bottom: map-get($spacers, 1) !important;
    }
  }
}
