.btn {
  &-wrapper {
    &-clear {
      display: grid;
      margin-right: 0;
      margin-top: map-get($spacers, 2);
      order: 1;

      @include media-breakpoint-up(sm) {
        margin-right: map-get($spacers, 2);
        margin-top: 0;
        order: 0;
      }
    }

    &-save {
      display: grid;
      order: 0;

      @include media-breakpoint-up(sm) {
        flex-grow: 1;
        order: 1;
      }
    }
  }
}
